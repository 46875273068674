import { ref } from "vue";
export const trumbowygEditor = ref(null);
export const pageSelectRef = ref(null);
export const cropImgFlag = ref(false);
export const blockOverlayActive = ref(false);
export const show = ref("general");
export const languages = [
  { name: "Español", code: "es" },
  { name: "Catalán", code: "ca" },
  { name: "Gallego", code: "gl" },
  { name: "Euskera", code: "eu" },
  { name: "Inglés", code: "en" },
  { name: "Alemán", code: "de" },
  { name: "Danés", code: "da" },
  { name: "Sueco", code: "sv" },
  { name: "Francés", code: "fr" },
  { name: "Italiano", code: "it" },
  { name: "Portugués", code: "pt" },
  { name: "Ruso", code: "ru" },
  { name: "Rumano", code: "ro" },
  { name: "Neerlandés", code: "nl" },
  { name: "Griego", code: "el" },
  { name: "Turko", code: "tr" },
];
export const selectedLangs = ref(new Set(languages.map((lang) => lang.code)));
export const languagesChanged = ref(false);

export const toggleLang = (code) => {
  languagesChanged.value = true;
  if (selectedLangs.value.has(code)) {
    selectedLangs.value.delete(code);
  } else {
    selectedLangs.value.add(code);
  }
};

export const toggleBlockOverlay = (newValue) => {
  blockOverlayActive.value = newValue;
};
