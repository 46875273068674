<template>
  <div id="generalView" class="page-header">
    <div id="viewMain" class="header-wrapper row m-0">
      <div class="header-logo-wrapper col-auto p-0"></div>
      <div id="viewControllers" class="col ps-0">
        <div id="viewMain" class="d-flex align-items-center justify-content-between">
          <div class="wrap-tooglesidebar d-flex align-items-center justify-content-between">
            <div id="toogleSidebar" class="toogle-sidebar"><i class="fa-solid fa-bars"></i></div>
            <div>
              <select ref="pageSelect" class="form-select" id="viewSelect" v-model="pageSelected">
                <option v-for="page in pages" :key="page.key"  v-bind:value="page.key">
                  {{ page.titulo }}
                </option>
              </select>
            </div>
          </div>
          <div class="hstack gap-2">
            <button class="btn btn-outline-primary rounded-pill VPublicada" v-on:click="VPublicada"
              v-if="cliente.VersionGuardada">Cargar versión publicada</button>
            <button class="btn btn-primary rounded-pill" id="publicar" v-on:click="publicar">Publicar</button>
            <div class="divider"></div>
            <button class="btn btn-outline-primary rounded-pill" v-on:click="redirectToUserWeb">Mi Web <i
                class="fa-solid fa-arrow-up-right-from-square"></i></button>
            <BtnLang @saveLanguages="$emit('saveLanguages')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-fluid">
      <PantallaGeneral ref="pantalla" @clicked="onClickChild" :folder="cliente.Carpeta_imagenes"
        :pageSelected="pageSelected" :cliente="cliente" :timeStamp="timeStamp" class="notranslate" />
    </div>
  </div>
</template>

<script class="notranslate">
import { onMounted, ref } from 'vue';
import PantallaGeneral from '../components/PantallaGeneral.vue'
import BtnLang from '../components/BtnLang.vue'
import { pageSelectRef } from '@/sharedState';

export default {
  name: 'GeneralView',
  components: {
    PantallaGeneral,
    BtnLang
  },
  emits: ['saveLanguages'],
  props: {
    cliente: Object,
    timeStamp: Object
  },
  data() {
    return {
      pages: [
        { titulo: 'Inicio' , key : 'Inicio'},
        { titulo: 'Servicios', key : 'Servicios' },
        { titulo: 'Sobre Nosotros', key : 'Sobre Nosotros' },
        { titulo: 'Contacto', key : 'Contacto' }
      ],
      pageSelected: 'Inicio',
    }
  },
  setup() {
    const pageSelect = ref(null)

    onMounted(() => {
      pageSelectRef.value = pageSelect.value
    })

    return {
      pageSelect
    }
  },
  methods: {
    onClickChild(value) {
      this.$emit('clicked', value)
    },
    redirectToUserWeb() {
      const href = window.location.href
      const userWeb =
        href.includes("localhost") || href.includes('padawan')
          ? "https://web.padawan.com.es"
          : href.replace("cms.", "");
      window.open(userWeb)
    },
    publicar() {
      this.$emit('publish', 'publish')
    },
    VPublicada() {
      this.$emit('vpublicada')
    }
  },
  watch: {
    pageSelected: function () { // watch it
      if (window.send_analytics != undefined) {
        window.send_analytics(`Click.Menu.${this.pageSelected.replace(' ', '')}`);
      }
      this.$refs.pantalla.cleanSelect();
      this.$emit('clicked', 'general')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.divider {
  width: 1px;
  height: 20px;
  background-color: #c3c0d4;
  margin-inline: 10px;
}
</style>
