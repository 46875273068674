<template>
  <div id="tour-widget-container" v-if="!tourStarted">
    <div class="tour-widget-content" v-if="tourListOpen">
      <div class="tour-progress-container">
        <progress
          :max="Object.keys(tourItems).length"
          :value="tourCompletionProgress"
        ></progress>
        <span
          >{{
            parseInt(
              (tourCompletionProgress * 100) / Object.keys(tourItems).length
            )
          }}%</span
        >
        <span translate="no" role="button" @click="toggleTourListOpen">X</span>
      </div>
      <ul>
        <li v-for="(value, key) in tourItems" :key="key">
          <div class="tour-item">
            <div class="custom-checkbox">
              <input
                type="checkbox"
                :id="key"
                v-model="value.completed"
                disabled
              />
              <label :for="key"></label>
            </div>
            <div class="tour-item-texts" @click="setupAndStartTour(key)">
              <strong :class="{ completed: value.completed }">{{
                value.title
              }}</strong>
              <p v-if="value.description && !value.completed">
                {{ value.description }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <button @click="toggleTourListOpen">Tutoriales</button>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from "vue";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import { getCompletedTours, markTourCompleted } from "@/toursInfo";
import {
  cropImgFlag,
  show,
  toggleBlockOverlay,
  trumbowygEditor,
} from "@/sharedState";

const activeTour = ref(null);
const tourListOpen = ref(false);
const tourStarted = ref(false);
const tourCompletionProgress = ref(0);

const tourItems = ref({
  globalTour: {
    title: "Elige el aspecto de tu web 🎨",
    description:
      "Lleva tu marca a tu sitio web: escoge tu color representativo, añade un logo y selecciona la tipografía.",
    completed: false,
    cleanup() {
      $("#header").off(".tourEvent");
      $("#main-color-picker-button").off(".tourEvent");
      $("#main-color-picker-colors").off(".tourEvent");
      $("#favicon-input").off(".tourEvent");
      $("#modal-cropper").off("click.tourEvent");
      $("#cancel-crop-btn, #cut-crop-btn").off("click.tourEvent");
      $("#header").off(".tourEvent");
      $("#edit-logo-input-group").off("click.tourEvent");
    },
    steps: [
      {
        element: "#editMain",
        popover: {
          description:
            "<p>En este menú lateral puedes <b>personalizar los elementos visuales</b> de cada página.</p>",
          onPopoverRender: function () {
            // mostrar los items de edicion general
            // en el panel de GeneralEdit
            show.value = "general";

            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: "#main-color-picker",
        popover: {
          description:
            "<p>Elige el <b>color principal y secundario</b> de tu página.</p>",
          onPopoverRender: function () {
            // cuando se haga click en el color, recalcular el highlight
            $("#main-color-picker-button").on("click.tourEvent", () => {
              activeTour.value.refresh();
              // al hacer click en un color, avanzar al siguiente paso
            });
            $("#main-color-picker-colors").on("click.tourEvent", function () {
              activeTour.value.refresh();
              activeTour.value.moveTo(2);
            });
            toggleBlockOverlay(false);
          },
        },
      },
      {
        element: "#font-selector",
        popover: {
          description:
            "<p>Escoge <b>un tipo de letra</b> claro y atractivo que fomente la legibilidad y mantenga la coherencia visual</p>",
          onPopoverRender: function () {
            $("#fontSelect").on("change", () => {
              activeTour.value.moveTo(3);
            });
            toggleBlockOverlay(false);
          },
        },
      },
      {
        element: "#favicon-editor",
        popover: {
          description:
            "<p>Sube un pequeño <b>ícono que represente a tu página web en la pestaña del navegador</b>.</p><p>Esto ayudará a los usuarios a identificar tu marca visualmente en su navegación por Internet.<p>",
          onPopoverRender: function () {
            show.value = "general";
            toggleBlockOverlay(false);
            $("#favicon-input").on("change.tourEvent", function () {
              activeTour.value.moveTo(4);
            });
          },
          onNextClick() {
            activeTour.value.moveTo(5);
          },
        },
      },
      {
        element: "#modal-cropper",
        popover: {
          description: `<p>Aquí puedes modificar el favicon a tu gusto y cuando hayas terminado presionar "Cancelar" o "Cortar".</p>`,
          side: "left",
          align: "center",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
          onPrevClick() {
            cropImgFlag.value = false;
            activeTour.value.moveTo(3);
          },
          onNextClick() {
            cropImgFlag.value = false;
            activeTour.value.moveTo(5);
          },
        },
      },
      {
        element: "#header",
        popover: {
          description:
            "<p>Para añadir <b>tu logo</b> a la página web, <b>haz clic en esta sección destacada</b> para abrir el menú de edición.</p>",
          disableButtons: ["next"],
          side: "bottom",
          align: "center",
          onPopoverRender: function () {
            $("#header").on("click.tourEvent", function () {
              activeTour.value.moveTo(6);
            });
            toggleBlockOverlay(false);
          },
          onPrevClick: async function () {
            show.value = "general";
            await nextTick();
            cropImgFlag.value = false;
            activeTour.value.moveTo(3);
          },
        },
      },
      {
        element: "#edit-logo-input-group",
        popover: {
          description: "Aquí puedes subir el logo para tu página web",
          onPopoverRender: function () {
            $("#edit-logo-input-group").on("click.tourEvent", function () {
              activeTour.value.moveNext();
            });
            toggleBlockOverlay(true);
          },
        },
      },
    ],
  },
  homeTour: {
    title: "Configura tu página de inicio 🏠",
    description:
      "Personaliza tu página Inicio para transmitir de manera clara qué es tu negocio, qué ofrece y cómo puede ayudar a los usuarios.",
    completed: false,
    cleanup() {
      $("#viewSelect").off(".tourEvent");
      $("#principal").off(".tourEvent");
      $("#servicios").off(".tourEvent");
      $("#save-button").off(".tourEvent");
    },
    steps: [
      {
        element: "#viewSelect",
        popover: {
          description: `En este desplegable puedes seleccionar qué página de tu sitio web editar. ¡Empieza con la página <code>Inicio</code>!`,
          onPopoverRender: function () {
            $("#viewSelect").on("change.tourEvent", (event) => {
              if (event.target.value === "Inicio") {
                activeTour.value.moveTo(1);
              }
            });
          },
          onNextClick: function (element) {
            if (element.value !== "Inicio") {
              alert(`Por favor, selecciona "Inicio" para continuar`);
              return;
            }
            activeTour.value.moveTo(1);
          },
        },
      },
      {
        element: "#principal",
        popover: {
          description:
            "<p><b>Clica sobre esta sección</b> de la página Inicio para <b>abrir el editor de texto</b>. Podrás ir seleccionando cada sección de la web para personalizarla.</p>",
          disableButtons: ["next"],
          onPopoverRender: function () {
            $("#principal").on("click.tourEvent", () => {
              activeTour.value.moveTo(2);
            });
            toggleBlockOverlay(false);
          },
        },
      },
      {
        element: "#editMain",
        popover: {
          description:
            "Ahora vamos a personalizar el texto principal donde se presenta a tu negocio.",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: "#main-title-input",
        popover: {
          description:
            "<p><b>Escribe el nombre comercial</b> de tu negocio. Lo que escribas aparecerá como título de tu web.</p>",
          onPopoverRender: function () {
            toggleBlockOverlay(false);
          },
        },
      },
      {
        element: "#main-text-textarea",
        popover: {
          description:
            "<p><b>Crea una descripción</b> clara y atractiva, destacando a qué se dedica y qué ofrece.<p>",
          onPopoverRender: function () {
            toggleBlockOverlay(false);
          },
        },
      },
      {
        element: "#main-text-textarea .trumbowyg-button-group:first-child",
        popover: {
          description:
            "<p>Con <b>estos botones puedes retroceder un paso</b> atrás en el texto (deshacer acción) <b>o recuperar la acción anteriormente deshecha</b> (rehacer).</p>",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: "#main-text-textarea .trumbowyg-button-group:nth-child(3)",
        popover: {
          description:
            "<p>¡También puedes añadir <b>enlaces externos al texto</b>! Para ello selecciona una palabra y a continuación pulsa <b>insertar link</b> desde este botón.</p>",
          onNextClick() {
            trumbowygEditor.value?.el?.trumbowyg("execCmd", {
              cmd: "createLink",
            });
            activeTour.value.moveTo(7);
          },
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".trumbowyg-modal .trumbowyg-input-row:nth-child(1)",
        popover: {
          description:
            "<p>En este primer texto, <b>inserta la URL</b> o enlace al que quieres vincular el texto.</p>",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".trumbowyg-modal .trumbowyg-input-row:nth-child(2)",
        popover: {
          description:
            "<p>En este otro texto, <b>escribe el texto que deseas que se visualice</b>.</p>",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".trumbowyg-modal .trumbowyg-input-row:nth-child(3)",
        popover: {
          description: `<p><b>Escoge un título. Solo será visible cuando un usuario posicione el ratón por encima del enlace</b>.</p>
          <p>Por ejemplo, si lo estás vinculando a tu portal de comercio electrónico, puedes usar como título <i>Accede a nuestra tienda online</i>.</p>`,
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".trumbowyg-modal .trumbowyg-input-row:nth-child(4)",
        popover: {
          description:
            "<p><b>Escoge</b> si prefieres que el enlace <b>se abra en la pestaña actual</b> (<code>Same tab</code>) <b>o en una nueva pestaña</b> (<code>New tab</code>) del navegador cuando los usuarios hagan click sobre el enlace.</p>",
          onNextClick: function () {
            trumbowygEditor.value?.el?.trumbowyg("closeModal");
            activeTour.value.moveTo(11);
          },
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: "#main-text-textarea .trumbowyg-button-group:nth-child(4)",
        popover: {
          description: "Añade un superíndice con este botón si lo necesitas.",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
          onPrevClick: function () {
            trumbowygEditor.value?.el?.trumbowyg("execCmd", {
              cmd: "createLink",
            });
            activeTour.value.moveTo(10);
          },
        },
      },
      {
        element: "#main-text-textarea .trumbowyg-button-group:nth-child(5)",
        popover: {
          description:
            "<p>O usa este botón para <b>eliminar el formato del texto</b> si anteriormente has aplicado algún estilo.</p>",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".sidebar-footer .btn",
        popover: {
          description:
            "No te olvides de <b>pulsar el botón</b> <code>Guardar</code> <b>para mantener los cambios</b> que hayas realizado.",
          onPopoverRender: function () {
            // si hace click en guardar, cerramos el tour
            $("#save-button").on("click.tourEvent", () => {
              onEndHook("homeTour");
            });
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: "#servicios",
        popover: {
          description: `<p>En la página Inicio <b>podrás incluir tres servicios de tu negocio</b>, escoge los más importantes o representativos. Más adelante veremos como puedes incluir más servicios.</p>
        <p><b>Clica sobre esta sección</b> para acceder al editor.</p>`,
          disableButtons: ["next"],
          onPopoverRender: function () {
            $("#servicios").on("click.tourEvent", () => {
              activeTour.value.moveTo(15);
            });
            toggleBlockOverlay(false);
          },
        },
      },
      {
        element: "#editMain",
        popover: {
          description: `<p>Para cada servicio añade:</p><ul><li>Un nombre o <b>título</b>.</li><li>Una <b>breve descripción</b> del mismo con la información de interés para el usuario. Sé breve, más adelante podrás dar más detalle sobre cada servicio.</li><li>Una <b>imagen</b> representativa.</li></ul>`,
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: "#sobreNosotros",
        popover: {
          description: `<p>Aprovecha esta sección para hacer un <b>resumen de la historia, valores o misión de la empresa</b>.</p><p><b>Clica sobre ella</b> para abrir el editor.</p>`,
          disableButtons: ["next"],
          onPopoverRender: function () {
            $("#sobreNosotros").on("click.tourEvent", () => {
              activeTour.value.moveNext();
            });
            toggleBlockOverlay(false);
          },
        },
      },
    ],
  },
  servicesTour: {
    title: "Añade tus servicios 🛠️",
    description:
      "Muestra los servicios que ofreces, incluye precios, descripción e imágenes.",
    completed: false,
    cleanup() {
      $("#viewSelect").off(".tourEvent");
      $("#listServicios").off(".tourEvent");
    },
    steps: [
      {
        element: "#viewSelect",
        popover: {
          description: `<p>Selecciona <code>Servicios</code> en el desplegable para incluir todos los servicios de tu empresa.</p>`,
          onPopoverRender: function () {
            $("#viewSelect").on("change.tourEvent", (event) => {
              if (event.target.value === "Servicios") {
                activeTour.value.moveTo(1);
              }
            });
          },
          onNextClick: function (element) {
            if (element.value !== "Servicios") {
              alert(`Por favor, selecciona "Servicios" para continuar`);
              return;
            }
            activeTour.value.moveTo(1);
          },
        },
      },
      {
        element: "#listServicios",
        popover: {
          description: `<p>Esta sección te permite personalizar <strong>cómo se presentan los servicios de tu empresa</strong> en la página web.</p>
          <p>Aquí podrás modificar el nombre, la descripción y la imagen asociada de cada servicio.</p>
          <p>Haz click en la sección remarcada.</p>`,
          disableButtons: ["next"],
          side: "left",
          align: "center",
          onPopoverRender: function () {
            $("#listServicios").on("click.tourEvent", () => {
              activeTour.value.moveTo(2);
            });
          },
        },
      },
      {
        element: "#servicio-1-name-input",
        popover: {
          description: `<p><strong>Nombre del servicio: Dale a tu servicio un nombre</strong> claro y atractivo que capture la esencia de lo que ofreces.</p>
            <p>Asegúrate de que sea descriptivo pero conciso, para que los usuarios comprendan rápidamente de qué se trata.</p>`,
        },
      },
      {
        element: "#servicio-1-description-input",
        popover: {
          description: `<p><strong>Descripción del servicio: Explica de forma clara y más detallada en qué consiste el servicio</strong> y cómo beneficia al cliente.</p>
            <p>Destaca los puntos clave y únicos de tu oferta, enfocándote en lo que resuelve o mejora.</p>`,
        },
      },
      {
        element: "#service1",
        popover: {
          description: `<p><strong>Imagen del servicio</strong>: también puedes añadir una imagen que refleje visualmente tu servicio.</p>
          <p>Por ejemplo, es una buena idea añadir una imagen que refleje el resultado real obtenido por tu servicio para alguno de tus clientes.</p>`,
        },
      },
    ],
  },
  aboutUsTour: {
    title: `"Sobre Nosotros" 🧑`,
    description: "Cuenta la historia de tu negocio y su valor diferencial.",
    completed: false,
    cleanup() {
      $("#viewSelect").off(".tourEvent");
    },
    steps: [
      {
        element: "#viewSelect",
        popover: {
          description: `Selecciona <code>Sobre Nosotros</code> en el desplegable para editar esta página.`,
          onPopoverRender: function () {
            $("#viewSelect").on("change.tourEvent", (event) => {
              if (event.target.value === "Sobre Nosotros") {
                activeTour.value.moveTo(1);
              }
            });
          },
          onNextClick: function (element) {
            if (element.value !== "Sobre Nosotros") {
              alert(`Por favor, selecciona "Sobre Nosotros" para continuar`);
              return;
            }
            activeTour.value.moveTo(1);
          },
        },
      },
      {
        element: "#PantallaSobreNosotros",
        popover: {
          description: `<p>Esta sección te permite <strong>contar la historia y los valores de tu empresa de manera más personal</strong>.</p>
            <p>Incluye una breve descripción sobre cómo comenzó tu negocio, qué lo motiva, qué misión lo guía, o incluso qué te diferencia de tus competidores.</p>
            <p>Este espacio es clave para <strong>conectar emocionalmente con tus clientes</strong>, mostrando quién eres más allá de los servicios que ofreces.</p>`,
        },
      },
    ],
  },
  contactTour: {
    title: "Configura la página de Contacto 📧",
    description: "Asegúrate de que tus datos de contacto sean correctos.",
    completed: false,
    cleanup() {
      $("#viewSelect").off(".tourEvent");
    },
    steps: [
      {
        element: "#viewSelect",
        popover: {
          description: `Échale un vistazo a tu página de <code>Contacto</code>.`,
          onPopoverRender: function () {
            $("#viewSelect").on("change.tourEvent", (event) => {
              if (event.target.value === "Contacto") {
                activeTour.value.moveTo(1);
              }
            });
            toggleBlockOverlay(false);
          },
          onNextClick: function (element) {
            if (element.value !== "Contacto") {
              alert(`Por favor, selecciona "Contacto" para continuar`);
              return;
            }
            activeTour.value.moveTo(1);
          },
        },
      },
      {
        element: "#formulario",
        popover: {
          description: `<p>Los usuarios que visiten tu web tienen disponible <b>un formulario con el que te pueden contactar</b>.</p>
            <p>Cualquier contacto realizado a través de tu web te llegará a tu correo y/o whatsapp.</p>`,
          side: "left",
          align: "center",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".icons.gap-5",
        popover: {
          description: `<p>A través de estos botones, también pueden <b>contactarte a tú teléfono, whatsapp y correo electrónico</b>.</p>
          <p>Si necesitas modificar estos datos, comunícate con el equipo de soporte para solicitar el cambio.<p>`,
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
      {
        element: ".wrap-card",
        popover: {
          description:
            "¡Dale un toque personal añadiendo como imagen <b>una fotografía real de tu equipo o de tus instalaciones</b>!",
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
    ],
  },
  publishTour: {
    title: "¡Último paso! Publica tu web 🚀",
    completed: false,
    steps: [
      {
        element: "#publicar",
        popover: {
          description: `Cuando tu web esté completamente configurada, pulsa el botón de <code>Publicar</code> para <b>hacer visible al público la nueva versión</b>.`,
          onPopoverRender: function () {
            toggleBlockOverlay(true);
          },
        },
      },
    ],
  },
});

const toggleTourListOpen = () => {
  tourListOpen.value = !tourListOpen.value;
};
const toggleTourStarted = () => {
  tourStarted.value = !tourStarted.value;
};

const setToursCompletionStatus = () => {
  const completedTours = getCompletedTours();

  tourCompletionProgress.value = completedTours.length;

  completedTours.forEach((completedTour) => {
    if (tourItems.value[completedTour]) {
      tourItems.value[completedTour].completed = true;
    }
  });
};

const destroyActiveTour = () => {
  if (activeTour.value?.destroy) {
    activeTour.value.destroy();
  }

  activeTour.value = null;
};

const setupAndStartTour = (key) => {
  const tour = tourItems.value[key];
  // verificar que el item seleccionado tenga un tour configurado, por si acaso...
  if (!tour) return;

  // cerrar el widget de seleccion
  // y esconder el boton get started
  toggleTourStarted();

  // si por alguna razon el tour anterior no se terminó bien,
  // lo terminamos ahora
  destroyActiveTour();

  // inicializar tour
  const driverObj = driver({
    nextBtnText: "Sig",
    prevBtnText: "Ant",
    doneBtnText: "Hecho",
    allowClose: false,
    onDestroyed: () => onEndHook(key),
  });

  // agregar steps del tour
  driverObj.setSteps(tour.steps);
  // iniciar el tour visualmente
  driverObj.drive();

  // guardar referencia al driverObj para poder usar sus methods en los steps
  activeTour.value = driverObj;
};

onMounted(() => {
  setToursCompletionStatus();

  if (tourCompletionProgress.value === 0) {
    tourListOpen.value = true;
  }
});

const onEndHook = (key) => {
  // eliminar event listeners que hayan declarado durante este tour
  if (tourItems.value[key].cleanup) {
    tourItems.value[key].cleanup();
  }
  // eliminar el tour activo, asi no hay conflicto al iniciar otro tour distinto
  // solo puede haber uno a la vez o la libreria los confunde y los mezcla
  destroyActiveTour();
  markTourCompleted(key);
  setToursCompletionStatus();
  toggleTourStarted();
  toggleBlockOverlay(false);
};
</script>

<style scoped>
#tour-widget-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1054;
}

button {
  color: white;
  border-radius: 2rem;
  background-color: var(--main-primary-color);
  font-weight: bold;
  padding: 0.75rem 2rem;
  border: none;
  box-shadow: 5px 6px 8px #c7c7c770;
  font-size: 0.85rem;
  float: right;
}

.tour-widget-content {
  background-color: white;
  width: 385px;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 5px 6px 8px #c7c7c770;
  margin-bottom: 1rem;
}

.tour-widget-content ul {
  list-style: none;
  padding: 0;
}

.tour-item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.tour-item .tour-item-texts {
  cursor: pointer;
}

.tour-item p {
  margin: 0;
  opacity: 0.75;
}

.tour-item strong.completed {
  text-decoration: line-through;
  opacity: 0.8;
}

/* PROGRESS BAR */
.tour-progress-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
}

.tour-progress-container span {
  opacity: 0.75;
}

.tour-progress-container span[role="button"] {
  margin-left: auto;
  font-size: 1.1rem;
}

progress[value] {
  --color: #6362e7; /* the progress color */
  --background: #c7c7c770; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 200px;
  height: 5px;
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color);
}
/* PROGRESS BAR */

/* CUSTOM CHECKBOX */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-primary-color);
  border-radius: 10px;
  background-color: white;
  cursor: not-allowed;
  position: relative;
  transition: all 0.3s ease;
}

.custom-checkbox input[type="checkbox"]:checked + label {
  background-color: var(--main-primary-color);
  border-color: var(--main-primary-color);
}

.custom-checkbox input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox label {
  pointer-events: none;
}
/* CUSTOM CHECKBOX */
</style>
