const COMPLETED_TOURS_KEY = "cms-completed-tours";

export const getCompletedTours = () => {
  const completedToursString = localStorage.getItem(COMPLETED_TOURS_KEY);
  const completedTours = completedToursString
    ? JSON.parse(completedToursString)
    : [];
  return completedTours;
};

export const markTourCompleted = (tourName) => {
  const completedTours = getCompletedTours();
  const completedToursSet = new Set(completedTours).add(tourName);
  localStorage.setItem(
    COMPLETED_TOURS_KEY,
    JSON.stringify(Array.from(completedToursSet))
  );
};