<template>
  <div id="generalEdit" class="sidebar-wrapper">
    <div class="d-flex flex-column justify-content-between">
      <div id="logoDiv" class="logo-wrapper">
        <img class="img-fluid" alt="Logo" :src="cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Img_logo+ '?v=' + this.timeStamp">
      </div>
      <div id="editMain" class="sidebar-main">
        <!-- GENERAL -->
        <!-- Selector de Color -->
        <div v-if="show == 'general'" class="controllerDiv">
          <div id="main-color-picker" class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Elige el color principal</p>
            </div>
            <div class="colorDiv" v-show="showColors == 'false'">
              <div id="main-color-picker-button" v-on:click="showColorsDiv('true')">
                <p class="stretched-link">{{ editCliente.Datos_json.Color }}</p>
              </div>
            </div>
            <div class="colorsContainer" v-show="showColors == 'true'">
              <div id="main-color-picker-colors" class="colorsDisplay">
                <div class="colors" v-for="color in cliente.Colores" :key="color" :style="{ 'background-color': color }"
                  v-on:click="colorChange(color)"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Selector de fuente -->
        <div v-if="show == 'general'" class="controllerDiv">
          <div id="font-selector" class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Elige la fuente</p>
            </div>
            <select class="form-select notranslate" id="fontSelect" v-model="editCliente.Datos_json.Fuente"
              @change="onChangeFont()">
              <option v-for="fuente in cliente.Fuentes" :key="fuente">
                {{ fuente }}
              </option>
            </select>
          </div>
        </div>
        <!-- FavIcon -->
        <div v-if="show == 'general'" class="controllerDiv">
          <div id="favicon-editor" class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Editar Favicon</p>
            </div>
            <div class="controllerImgDrag" id="-1" v-on:click="inputImg(-1)" @dragover.prevent @drop.prevent="dragDrop">
              <div class="controllerImgMessage">
                <i class="fa-regular fa-solid fa-cloud-arrow-up" v-if="loadedImg == ''"></i>
                <div v-if="loadedImg == ''">Agrega o arrastra tu archivo aquí</div>
              </div>
              <input id="favicon-input" type="file" hidden ref="imgReader" @change="onChangeImg" accept="image/png,image/x-icon">
              <img :src="loadedImg" />
            </div>
            <div class="text-muted small pe-none mt-2">Sube una imagen PNG o ICO de 32x32 píxeles</div>
          </div>
        </div>
        <!-- HEADER -->
        <!-- Selector de Img -->
        <div v-if="show == 'header'" id="edit-logo-input-group" class="controllerDiv">
          <div class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Editar Logo</p>
            </div>
            <div class="controllerImgDrag" id="0" v-on:click="inputImg(0)" @dragover.prevent @drop.prevent="dragDrop">
              <div class="controllerImgMessage">
                <i class="fa-regular fa-solid fa-cloud-arrow-up" v-if="loadedImg == ''"></i>
                <div v-if="loadedImg == ''">Agrega o arrastra tu archivo aquí</div>
              </div>
              <input type="file" hidden ref="imgReader" @change="onChangeImg" accept="image/jpeg, image/jpg, image/png">
              <img :src="loadedImg" />
            </div>
            <div class="text-muted small pe-none mt-2">Sube una imagen del tipo PNG o JPG de hasta 2 MB</div>
          </div>
        </div>

        <!-- INFORMACIÓN PRINCIPAL -->
        <div v-if="show === 'principal'" class="controllerDiv">
          <div class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Información principal</p>
            </div>
            <div id="main-title-input" class="inputTextDiv notranslate">
              <input type="text" placeholder="Titulo" class="form-control inputText" v-model="editCliente.Datos_json.Titulo_principal">
            </div>
            <div id="main-text-textarea" class="inputTextDiv notranslate">
              <trumbowyg ref="mainTextAreaEditor" v-model="editCliente.Datos_json.Descripcion_principal" class="form-control" :config="config">
              </trumbowyg>
            </div>
          </div>
        </div>

        <!-- SERVICIOS -->
        <div class="wrap-services" v-if="show == 'servicios'">
          <div class="controllerDiv" v-for="(service, index) in editCliente.Datos_json.Servicios.slice(0, 3)"
            :key="index">
            <div class="controllerInnerDiv">
              <div class="controllerTextDiv" style="justify-content: space-between; align-items: center;">
                <p class="controllerText">Servicio {{ index + 1 }}</p>
                <span class="action-box delete-btn">
                  <i class="fa-regular fa-trash-can" v-on:click="deleteService(index)"></i>
                </span>
              </div>
              <div class="inputTextDiv notranslate">
                <input type="text" class="form-control inputText" v-model="service.Titulo">
              </div>
              <div class="inputTextDiv notranslate">
                <trumbowyg v-model="service.Descripcion" class="form-control" :config="config"></trumbowyg>
              </div>
              <div class="controllerTextDiv">
                <p class="controllerText">Imagen</p>
              </div>
              <div class="controllerImgDrag" :id="'service' + (index + 1)" v-on:click="inputImg(index + 1)"
                @dragover.prevent @drop.prevent="dragDrop">
                <div class="controllerImgMessage">
                  <i class="fa-regular  fa-solid fa-cloud-arrow-up" v-if="!serviceloadedImg[index]"></i>
                  <div v-if="!serviceloadedImg[index]">Agrega o arrastra tu archivo aquí</div>
                </div>
                <img :src="serviceloadedImg[index]" />
              </div>
              <div class="text-muted small pe-none mt-2">Sube una imagen del tipo PNG o JPG de hasta 2 MB</div>
            </div>
          </div>
          <input type="file" hidden ref="imgReader" @change="onChangeImg" accept="image/jpeg, image/jpg, image/png">
        </div>

        <!-- SOBRE NOSOTROS -->
        <div v-if="(show == 'sobreNosotros' ||show == 'PantallaSobreNosotros')">
          <div class="controllerDiv">
            <div class="controllerInnerDiv">
              <div class="controllerTextDiv" style="justify-content: space-between; align-items: center;">
                <p class="controllerText">Descripción sobre el negocio</p>
              </div>
              <div class="inputTextDiv notranslate">
                <trumbowyg v-if="show== 'sobreNosotros'" v-model="editCliente.Datos_json.Descripcion_sobre_nosotros_corta" class="form-control"
                  :config="config"></trumbowyg>
                <trumbowyg v-if="show== 'PantallaSobreNosotros'" v-model="editCliente.Datos_json.Descripcion_sobre_nosotros" class="form-control"
                  :config="config"></trumbowyg>
              </div>
              <div class="controllerTextDiv">
                <p class="controllerText">Cambiar imagen</p>
              </div>
              <div class="controllerImgDrag" id="0" v-on:click="inputImg(-2)" @dragover.prevent @drop.prevent="dragDrop">
                <div class="controllerImgMessage">
                  <i class="fa-regular fa-solid fa-cloud-arrow-up" v-if="loadedImg == ''"></i>
                  <div v-if="loadedImg == ''">Agrega o arrastra tu archivo aquí</div>
                </div>
                <input type="file" hidden ref="imgReader" @change="onChangeImg"
                  accept="image/jpeg, image/jpg, image/png">
                <img :src="loadedImg" />
              </div>
              <div class="text-muted small pe-none mt-2">Sube una imagen del tipo PNG o JPG de hasta 2 MB</div>
            </div>
          </div>
        </div>
        <!-- DIRECCION -->
        <div v-if="show == 'find-us'" class="controllerDiv">
          <div class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Localización</p>
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Calle y Número" class="form-control inputText" v-model="editCliente.Datos_json.Calle">
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Código Postal *" class="form-control inputText" v-model="editCliente.Datos_json.cp">
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Localidad *" class="form-control inputText" v-model="editCliente.Datos_json.Localidad">
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Provincia *" class="form-control inputText" v-model="editCliente.Datos_json.Provincia">
            </div>
            <div class="controllerTextDiv">
              <p class="controllerText">Mapa Google</p>
            </div>
            <div class="inputTextDiv">
              <input type="text" placeholder="Dirección Google Maps" class="form-control inputText" v-model="editCliente.Datos_json.MapaGoogle">
            </div>
          </div>
        </div>
        <!-- CRITICAS -->
        <div class=infoDiv v-if="show == 'criticas'">
          <i class="fa-solid fa-circle-info"></i>
          <p> Estas reseñas serán obtenidas de Google...</p>
        </div>

        <!-- DATOS DE CONTACTO -->
        <!-- Selector de Img -->
        <div v-if="show == 'contacto'" class="controllerDiv">
          <div class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Cambiar imagen</p>
            </div>
            <div class="controllerImgDrag" id="0" v-on:click="inputImg(-2)" @dragover.prevent @drop.prevent="dragDrop">
              <div class="controllerImgMessage">
                <i class="fa-regular fa-solid fa-cloud-arrow-up" v-if="loadedImg == ''"></i>
                <div v-if="loadedImg == ''">Agrega o arrastra tu archivo aquí</div>
              </div>
              <input type="file" hidden ref="imgReader" @change="onChangeImg" accept="image/jpeg, image/jpg, image/png">
              <img :src="loadedImg" />
            </div>
            <div class="text-muted small pe-none mt-2">Sube una imagen del tipo PNG o JPG de hasta 2 MB</div>
          </div>
        </div>

        <!-- PANTALLA SERVICIOS MODIFICAR SERVICIOS -->
        <div v-if="show == 'listServicios'">
          <div class="controllerDiv" v-for="(service, index) in editCliente.Datos_json.Servicios" :key="index">
            <div class="controllerInnerDiv">
              <div class="controllerTextDiv" style="justify-content: space-between; align-items: center;">
                <p class="controllerText">Servicio {{ index + 1 }}</p>
                <span class="action-box delete-btn">
                  <i class="fa-regular fa-trash-can" v-on:click="deleteService(index)"></i>
                </span>
              </div>
              <div :id="`servicio-${index+1}-name-input`" class="inputTextDiv notranslate">
                <input type="text" class="form-control inputText" v-model="service.Titulo">
              </div>
              <div :id="`servicio-${index+1}-description-input`" class="inputTextDiv notranslate">
                <trumbowyg v-model="service.Descripcion" class="form-control" :config="config"></trumbowyg>
              </div>
              <div class="controllerTextDiv">
                <p class="controllerText">Imagen principal</p>
              </div>
              <div class="controllerImgDrag" :id="'service' + (index + 1)" v-on:click="inputImg(index + 1)"
                @dragover.prevent @drop.prevent="dragDrop">
                <div class="controllerImgMessage">
                  <i class="fa-regular fa-solid fa-cloud-arrow-up" v-if="!serviceloadedImg[index]"></i>
                  <div v-if="!serviceloadedImg[index]">Agrega o arrastra tu archivo aquí</div>
                </div>
                <img :src="serviceloadedImg[index]" />
              </div>
              <div class="text-muted small pe-none mt-2">Sube una imagen del tipo PNG o JPG de hasta 2 MB</div>
            </div>
          </div>
          <input type="file" hidden ref="imgReader" @change="onChangeImg" accept="image/jpeg, image/jpg, image/png">
        </div>

        <!-- FOOTER -->
        <div v-if="show == 'footer'" class="controllerDiv">
          <div class="controllerInnerDiv">
            <div class="controllerTextDiv">
              <p class="controllerText">Redes sociales</p>
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Faceboook" class="form-control inputText"
                v-model="editCliente.Datos_json.Perfil_facebook">
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Instagram" class="form-control inputText"
                v-model="editCliente.Datos_json.Perfil_instagram">
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="LinkedIn" class="form-control inputText"
                v-model="editCliente.Datos_json.Perfil_linkedin">
            </div>
            <div class="inputTextDiv notranslate">
              <input type="text" placeholder="Twitter" class="form-control inputText"
                v-model="editCliente.Datos_json.Perfil_twitter">
            </div>
          </div>
        </div>

      </div>
      <div class="sidebar-footer gap-2 px-5 py-4" v-if="show != 'criticas'">
        <button class="btn btn-outline-primary" v-on:click="addService('home')" v-if="show == 'servicios'">Agregar
          servicio</button>
        <button class="btn btn-outline-primary" v-on:click="addService('servicios')" v-if="show == 'listServicios'">Agregar
          servicio</button>
        <button id="save-button" class="btn btn-primary" v-on:click="confirmarGuardar">Guardar</button>
      </div>
    </div>

  </div>
</template>

<script class="notranslate">
import { useToast } from "vue-toastification";
import axios from "axios"
import { trumbowygEditor } from "@/sharedState";

export default {
  name: 'GeneralEdit',
  props: {
    show: String,
    cliente: Object,
    folder: String
  },

  mounted() {
    this.editCliente = this.cliente;
  },
  updated() {
    trumbowygEditor.value = this.$refs.mainTextAreaEditor
  },
  data() {
    return {
      config: {
        autogrow: true,
        removeformatPasted: true,
        btns: [
          ['undo', 'redo'], // Only supported in Blink browsers
          ['strong', 'em', 'del'],
          ['link'],
          ['superscript'],
          ['removeformat']
        ]
      },
      showColors: 'false',
      loadedImg: '',
      loadedImgType: '',
      serviceloadedImg: [],
      serviceloadedImgType: [],
      savedImg: [],
      deletedImg: [],
      imgInputSelected: 0,
      editCliente: {
        Datos_json: {
          Color: "#4f8cf0",
          Servicios: []
        }
      },
    }
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast }
  },
  methods: {
    confirmarGuardar() {
      this.$emit('modalSave');
    },
    async guardar() {
      if (window.send_analytics != undefined) {
        window.send_analytics("Click.Guardar");
      }
      var response;
      switch (this.show) {
        case 'servicios':
        case 'listServicios':
          if (this.comprobarServicios()) {
            for (var i in this.savedImg) {
              if (this.savedImg[i] != null && this.savedImg[i] != ''){
                response = await this.guardarImg(this.folder + '/' + this.savedImg[i].id, this.savedImg[i].img, this.savedImg[i].title, 'services', this.savedImg[i].description);
                if (response.status != 200){
                  
                  return false;
                }
              }
            }
            for (var x in this.deletedImg) {
              if (this.deletedImg[x] != null && this.deletedImg[x] != ''){
                response = await this.borrarImg(this.folder + '/' + this.deletedImg[x]);
                if(!response){
                  //return false;
                }else if (response.status != 200){
                  return false;
                }
              }
            }
            
            return true;
          }
          break;
        case 'header':
          if (this.loadedImg) {
            response = await this.guardarImg(this.folder + '/logo' + this.loadedImgType, this.loadedImg.split("base64,")[1], this.removeAccents('Logo de ' + this.editCliente.Datos_json.Titulo_principal), 'logo');
            if (response.status == 200) {
              this.editCliente.Datos_json.Img_logo = 'logo' + this.loadedImgType;
              return true;
            } else
              return false;
          }
          break;
        case 'principal':
          return this.comprobarPrincipal();
        case 'contacto':
          if (this.loadedImg) {
            response = await this.guardarImg(this.folder + '/contacto' + this.loadedImgType, this.loadedImg.split("base64,")[1], this.removeAccents('Contacta con ' + this.editCliente.Datos_json.Titulo_principal), 'contact');
            if (response.status == 200) {
              this.editCliente.Datos_json.Img_contacto = 'contacto' + this.loadedImgType;
              return true;
            } else
              return false
          }
          break;
        case 'sobreNosotros':
        case 'PantallaSobreNosotros':
          if (this.comprobarSobreNosotros()) {
            if (this.loadedImg) {
              response = await this.guardarImg(this.folder + '/sobreNosotros' + this.loadedImgType, this.loadedImg.split("base64,")[1], this.removeAccents('Sobre ' + this.editCliente.Datos_json.Titulo_principal), 'about_us', this.cleanDescription(this.editCliente.Datos_json.Descripcion_sobre_nosotros));
              if (response.status == 200) {
                this.editCliente.Datos_json.Img_sobre_nosotros = 'sobreNosotros' + this.loadedImgType;
                return true;
              } else
                return false
            }
            return true;
          }
          break;
        case 'footer':
          return this.comprobarFooter();
        case 'general':
          if (this.loadedImg) {
            response = await this.guardarImg(this.folder + '/favicon' + this.loadedImgType, this.loadedImg.split("base64,")[1], this.removeAccents('Favicon de '+ this.editCliente.Datos_json.Titulo_principal), 'favicon');
            if (response.status == 200) {
              this.editCliente.Datos_json.FavIcon = 'favicon' + this.loadedImgType;
              return true;
            } else
              return false
          }
          return true;
        case 'find-us':
          return this.comprobarDireccion();
        default:
          return false;
      }
      return false
    },
    passEditClient() {
      return this.editCliente.Datos_json;
    },
    onChangeImg() {
      let file = this.$refs.imgReader.files[0];
      this.readImg(file);
    },
    inputImg(id) {
      this.imgInputSelected = id;
      this.$refs.imgReader.click();
    },
    dragDrop(e) {
      let id = e.path[0].id.replace('service', '');
      if (id)
        this.imgInputSelected = id;
      else
        this.imgInputSelected = e.path[1].id;
      let file = e.dataTransfer.files[0];
      this.readImg(file);
    },
    readImg(file) {
      let validExtensions = ["image/jpeg", "image/jpg", "image/png", "image/x-icon"]; // tipos de img permitidos
      // Max 2MB de img
      if (file.size <= 16000000) {
        if (validExtensions.includes(file.type)) {
          let type = '.' + file.type.replace('image/', '');
          // Si el fichero es de tipo ico le agregamos la extension
          if (type == '.x-icon')
            type = ".ico";
          const fileReader = new FileReader();
          fileReader.onload = () => {
            // Si es mayor que 0 indica que es un servicio
            if (this.imgInputSelected > 0) {
              this.serviceloadedImg[this.imgInputSelected - 1] = fileReader.result;
              this.serviceloadedImgType[this.imgInputSelected - 1] = type;
              this.$emit('uploadImage', fileReader.result, 680, 680, 300, 250, 1/1);
            }
            else {
              // Si estamos intentando subir el favicon , comprobamos el tamaño en px
              if (this.imgInputSelected == -1) {
                var _URL = window.URL || window.webkitURL;
                var img;
                img = new Image();
                img.onload = () => {
                  if (img.width > 32 || img.height > 32)
                    this.toast.error("El tamaño máximo permitido para un favicon es 32px x 32px")
                  else {
                    this.loadedImg = fileReader.result; //passing user file source in fileURL variable
                    this.loadedImgType = type;
                    //alert("asd")
                    this.$emit('uploadImage', fileReader.result, 32, 32, 400, 250, 1/1);
                  }
                };
                img.src = _URL.createObjectURL(file);
              //Para la imagen de contacto y sobre nosotros
              } else if (this.imgInputSelected == -2) {                
                this.loadedImg = fileReader.result; //passing user file source in fileURL variable
                this.loadedImgType = type;
                this.$emit('uploadImage', fileReader.result, 680, 515, 400, 300, 4/3);
              //Para la imagen del logo
              }else {                
                this.loadedImg = fileReader.result; //passing user file source in fileURL variable
                this.loadedImgType = type;
                this.$emit('uploadImage', fileReader.result, 300, 181, 400, 100, 16/6);
              }
            }
          }
          fileReader.readAsDataURL(file);
        } else {
          this.toast.error("Esto no es un formato de imagen valido");
        }
      } else {
        this.toast.error("El tamaño de archivo máximo permitido son 2MB");
      }

    },
    deleteService(index) {
      if (this.editCliente.Datos_json.Servicios.length > 1) {
        this.deletedImg.push(this.cliente.Datos_json.Servicios[index].Img);
        this.editCliente.Datos_json.Servicios.splice(index, 1);
        this.toast.info("Servicio eliminado");
        this.serviceloadedImg.splice(index, 1);
      } else {
        this.toast.error("Se requiere min 1 servicio");
      }
    },
    addService(origen) {
      var max = 0;
      if(origen == 'home')
        max = process.env.VUE_APP_MAX_HOME_SERVICES;
      else
        max = process.env.VUE_APP_MAX_SERVICES;
      if (this.editCliente.Datos_json.Servicios.length < max) {
        this.editCliente.Datos_json.Servicios.push({ Titulo: '', Descripcion: '', Img: '' });
        this.toast.info("Servicio añadido");
      } else {
        this.toast.error("Se ha alcanzado el número max de servicios para esta sección");
      }
    },
    showColorsDiv(value) {
      this.showColors = value;
    },
    colorChange(newColor) {
      this.editCliente.Datos_json.Color = newColor;
      this.editCliente.Datos_json.Contraste = this.cliente.MatrizColores[newColor];
      this.$emit('colorChanged', this.editCliente.Datos_json.Color);
      this.showColorsDiv('false');
    },
    cleanImg() {
      this.loadedImg = '';
      this.loadedImgType = '';
      this.serviceloadedImg = [];
      this.serviceloadedImgType = [];
      this.imgInputSelected = 0;
    },
    async guardarImg(filename, base64img, title='', purpose='', description='') {
      let url = process.env.VUE_APP_API + 'guardar_imagen_cms';
      let img_metadata = {
        "title": title,
        "description": description,
        "purpose": purpose
      }
      // Eliminamos los campos vacios
      for (var i in img_metadata) {
        if (img_metadata[i] == '' || img_metadata[i] == undefined)
          delete img_metadata[i];
      }
      let body = {
        "name": filename,
        "img_str": base64img,
        "img_metadata" : img_metadata
      };
      let headers = {
        headers: {
          'Authorization': process.env.VUE_APP_APIKEY
        }
      };
      let response = await axios.post(url, body, headers);
      return response

    },
    async borrarImg(filename) {
      let url = process.env.VUE_APP_API + 'borrar_imagen';
      let body = {
        "name": filename,
      };
      let headers = {
        headers: {
          'Authorization': process.env.VUE_APP_APIKEY
        }
      };
      try {
        let response = await axios.post(url, body, headers, {
          timeout: 5000
        });
        return response;
      } catch (error) {
        this.toast.warning("El servicio eliminado no tenia imagen, se mantendra la imagen por defecto.")
      }
    },
    showCropImg(img) {
      if (this.imgInputSelected > 0)
        this.serviceloadedImg[this.imgInputSelected - 1] = img;
      else
        this.loadedImg = img;
    },
    /* COMPROBAR CAMPOS */
    comprobarPrincipal() {
      if (this.editCliente.Datos_json.Titulo_principal.replace(/\s+/g, '') == '' || this.editCliente.Datos_json.Descripcion_principal.replace(/\s+/g, '') == '') {
        this.errorForm();
        return false;
      }
      return true;
    },
    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
    },
    cleanDescription(description) {
      let desc = description.replace(/<[^>]*>?/gm, '');
      return this.removeAccents(desc);
    },
    comprobarServicios() {
      for (var i in this.serviceloadedImg) {
        var name = Date.now() + "_" + i + this.serviceloadedImgType[i];
        var description = this.cleanDescription(this.editCliente.Datos_json.Servicios[i].Descripcion);
        var title = this.removeAccents(this.editCliente.Datos_json.Servicios[i].Titulo);
        var originalImg = this.cliente.Datos_json.Servicios[i].Img; // La guardamos para borrarla, pues va a ser reemplazada
        this.editCliente.Datos_json.Servicios[i].Img = name;
        this.savedImg.push({ id: name, img: this.serviceloadedImg[i].split("base64,")[1], title: title, description: description });
        // Borramos la imagen original
        if (originalImg != null && 
        originalImg != '' && 
        originalImg != name && 
        !this.deletedImg.includes(originalImg)) {
          this.borrarImg(this.folder + '/' + originalImg);  
          // Las eliminamos de imagenes guardadas
          this.savedImg = this.savedImg.filter(img => img.id !== originalImg);
        }
      }
      for (var x in this.editCliente.Datos_json.Servicios) {
        let service = this.editCliente.Datos_json.Servicios[x];
        if (service.Titulo.replace(/\s+/g, '') == '' || service.Descripcion.replace(/\s+/g, '') == '') {
          this.errorForm();
          return false;
        }
        if(service.Img){
          if(service.Img.toString().replace(/\s+/g, '') == ''){
            this.errorForm();
            return false;
          }
        }

      }
      return true
    },
    comprobarSobreNosotros() {
      let check = this.editCliente.Datos_json.Descripcion_sobre_nosotros_corta;
      if(this.show == 'PantallaSobreNosotros')
        check = this.editCliente.Datos_json.Descripcion_sobre_nosotros;
      if (check.replace(/\s+/g, '') == '') {
        this.errorForm();
        return false;
      }
      return true;
    },
    comprobarDireccion() {
      // Comprobamos los campos obligatorios
      if (!this.editCliente.Datos_json.cp || this.editCliente.Datos_json.cp.replace(/\s+/g, '') == ''
      || !this.editCliente.Datos_json.Localidad ||  this.editCliente.Datos_json.Localidad.replace(/\s+/g, '') == ''
      || !this.editCliente.Datos_json.Provincia ||  this.editCliente.Datos_json.Provincia.replace(/\s+/g, '') == '') {
        this.errorForm();
        return false;
      }
      if(isNaN(Number(this.editCliente.Datos_json.cp))){
        this.toast.error("Revisa el campo Código Postal, no es un número")
        return false;
      }else{  
        return true;
      }
    },    
    comprobarFooter() {
      if (!this.validarURL(this.editCliente.Datos_json.Perfil_facebook)){
        this.toast.error("Revisa el campo Facebook, no es una URL válida")
        return false;
      }else if (!this.validarURL(this.editCliente.Datos_json.Perfil_instagram)){
        this.toast.error("Revisa el campo Instagram, no es una URL válida")
        return false;
      }else if (!this.validarURL(this.editCliente.Datos_json.Perfil_linkedin)){
        this.toast.error("Revisa el campo Linkedin, no es una URL válida")
        return false;
      }else if (!this.validarURL(this.editCliente.Datos_json.Perfil_twitter)){
        this.toast.error("Revisa el campo Twitter, no es una URL válida")
        return false;
      }else{
        return true;
      }      
    },    
    errorForm() {
      this.toast.error("Rellena todos los campos del formulario")
    },
    onChangeFont() {
      this.$emit('changeFont');
    },
    validarURL(curl) {
      if(curl.replace(/\s+/g, '') != ''){
        try {    
          new URL(curl);
          return true;    
        } catch (err) {    
          return false;    
        }
      }else{
        return true;
      }
    }
  },
  watch: {
    show: function () {
      this.cleanImg();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.controllerDiv {
  margin-bottom: 20px;
}

.wrap-services .controllerDiv {
  padding-bottom: 25px;
  border-bottom: 1px solid #dedeed;
}

.controllerInnerDiv {
  position: relative;
  display: flex;
  flex-direction: column;
}

.controllerTextDiv {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.controllerTextDiv .action-box {
  background: transparent;
  border: 1px solid transparent;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.controllerTextDiv .action-box i {
  font-size: 20px;
  cursor: pointer;
  color: #504f76;
  transition: all .25s ease-in-out;
}

.controllerTextDiv .action-box:hover {
  border: 1px solid var(--main-primary-color);
  background: #fff;
  border-radius: 4px;
}

.controllerTextDiv .action-box:hover i {
  color: var(--main-primary-color);
}

.controllerText {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  cursor: default;
}

/* SELECTOR DE COLOR */
.colorDiv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  border-radius: 10px;
  color: var(--main-color-contrast);
  background-color: var(--main-color-hexa);
  cursor: pointer;
}

.colorDiv div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorDiv p {
  margin: 0;
}

.colorsContainer {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.colorsContainer .colorsDisplay {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.colors {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  cursor: pointer;
}

/* SELECTOR DE IMG */
.controllerImgDrag {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #6362e7;
  border-radius: 15px;
  -o-border-image: none;
  border-image: none;
  background: rgba(99, 98, 231, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 175px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.controllerImgDrag .controllerImgMessage i {
  font-size: 2.5rem;
  color: #6362e7;
  margin-bottom: 5px;
}

.controllerImgDrag .controllerImgMessage div {
  font-size: 14px;
}

.controllerImgDrag img {
  max-width: 100%;
  height: auto;
}

/* INPUT TEXTO */
.inputTextDiv {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.controllerInnerDiv .inputTextDiv:last-child {
  margin-bottom: 0px !important;
}
</style>
