<template>
    <div class="custom-dropdown">
        <div data-bs-toggle="tooltip" data-bs-placement="bottom"
            data-bs-title="Selecciona los idiomas en los que quieres que se muestre tu web" ref="saveLanguagesBtn">
            <button id="saveLangsBtn" type="button" class="btn btn-outline-primary" @click="saveLanguages"
                :disabled="!languagesChanged">
                Guardar idiomas
            </button>
        </div>
        <button id="dropdownLangsBtn" type="button"
            class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
            aria-expanded="false">
            <span class="visually-hidden">Idiomas</span>
        </button>
        <ul class="dropdown-menu">
            <li v-for="lang in languages" :key="lang.code" @click.stop>
                <label class="checkbox-container" :for="`lang-${lang.code}`">
                    <input type="checkbox" :name="`lang-${lang.code}`" :id="`lang-${lang.code}`"
                        @change="toggleLang(lang.code)" :checked="selectedLangs.has(lang.code)">
                    <span class="checkmark"></span>
                    {{ lang.name }}
                </label>
            </li>
        </ul>
    </div>
</template>
<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import { Tooltip } from 'bootstrap';
import { languages, selectedLangs, languagesChanged, toggleLang } from '@/sharedState';
const emit = defineEmits(['saveLanguages'])

const saveLanguagesBtn = ref();

const saveLanguages = () => {
    languagesChanged.value = false;
    emit('saveLanguages')
}

onMounted(() => {
    // inicializar el tooltip
    new Tooltip(saveLanguagesBtn.value);
})
</script>

<style scoped>
.custom-dropdown {
    display: flex;
    flex-wrap: nowrap;
}

.custom-dropdown button {
    padding: 0 !important;
}

.custom-dropdown button#saveLangsBtn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none;
}

.custom-dropdown button#dropdownLangsBtn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.custom-dropdown li {
    padding: 0.25rem 1rem;
    user-select: none;
    cursor: pointer;
}

.dropdown-menu {
    padding: 0;
}

.dropdown-menu li:hover {
    background-color: #bbbbf1;
}

.dropdown-menu li label {
    margin-left: 0.25rem;
    cursor: pointer;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 4px;
    transition: all 0.3s;
}

.checkbox-container input:checked~.checkmark {
    background-color: #6362e7;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style>